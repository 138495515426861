import { css } from '@emotion/react';
import { FONT_PRIMARY, FONT_SECONDARY } from '~/theme/typography';
import { darken, lighten, Theme } from '@mui/material';

const Button = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiButton: {
			styleOverrides: {
				root: css`
					font-weight: 800;
					cursor: pointer;
					text-transform: none;
					font-family: ${FONT_PRIMARY};
					padding: ${theme.spacing(1.5, 3)};
					box-shadow: none;
					font-size: 18px;

					${theme.breakpoints.up('md')} {
						font-size: 20px;
					}

					&:hover,
					&:focus {
						box-shadow: none;
					}
				`,
			},
			defaultProps: {
				variant: 'contained',
				disableRipple: true,
			},
			variants: [
				{
					props: { variant: 'contained', color: 'primary' },
					style: css`
						color: ${theme.palette.common.white};
						background-color: ${theme.palette.primary.main} !important;
						border-radius: ${theme.spacing(1)} !important;

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.primary.main, 0.3)};
							color: ${theme.palette.primary.lighter};
						}
					`,
				},
				{
					props: { variant: 'contained', color: 'secondary' },
					style: css`
						color: ${theme.palette.common.white};
						background-color: ${theme.palette.secondary.main} !important;
						border-radius: ${theme.spacing(1)} !important;

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.secondary.main, 0.3)};
							color: ${theme.palette.primary.lighter};
						}
					`,
				},
				{
					props: { variant: 'contained', color: 'tertiary' },
					style: css`
						color: white !important;
						background-color: ${theme.palette.secondary.light} !important;
						border-radius: ${theme.spacing(1)} !important;

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.secondary.light, 0.1)};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'primary' },
					style: css`
						color: ${theme.palette.primary.dark};
						border: 2px solid ${theme.palette.primary.dark};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.primary.dark, 0.1)};
							color: ${theme.palette.primary.dark};
						}

						border-radius: ${theme.spacing(4)};
					`,
				},
				{
					props: { variant: 'outlined', color: 'secondary' },
					style: css`
						color: ${theme.palette.secondary.main};
						border-radius: ${theme.spacing(2)};

						border: 2px solid ${theme.palette.secondary.main};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.secondary.main, 0.1)};
							color: ${theme.palette.secondary.main};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'tertiary' },
					style: css`
						color: ${theme.palette.tertiary.main};
						border: 2px solid ${theme.palette.tertiary.light};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.tertiary.light, 0.1)};
							color: ${theme.palette.tertiary.main};
						}
					`,
				},
			],
		},
	};
};

export default Button;
