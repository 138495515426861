import styled from '@emotion/styled';
import {
	AccordionDetails,
	AccordionSummary,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Accordion as MuiAccordion,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Accordion: React.FC<{
	data: { summary: React.ReactNode; details: React.ReactNode; steps?: React.ReactNode[] };
}> = ({ data }) => {
	return (
		<Grid item xs={12} sm={6}>
			<Styles>
				<MuiAccordion className="accordion" elevation={0} square>
					<AccordionSummary
						className="accordion-summary"
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className="accordion-summary-title" variant="h4" component="p">
							{data?.summary}
						</Typography>
					</AccordionSummary>
					<AccordionDetails className="accordion-details">
						<Typography variant="body1">{data?.details}</Typography>
						{'steps' in data && data?.steps?.[0] && (
							<List>
								{data.steps?.map((item) => (
									<ListItem disablePadding disableGutters>
										<ListItemIcon
											sx={{
												color: 'secondary.darker',
												padding: 0,
												margin: 0,
												width: 'auto',
												height: 'auto',
												minWidth: '30px',
												transform: 'Scale(0.35)',
											}}
										>
											<FiberManualRecordIcon />
										</ListItemIcon>
										<ListItemText>{item}</ListItemText>
									</ListItem>
								))}
							</List>
						)}
					</AccordionDetails>
				</MuiAccordion>
			</Styles>
		</Grid>
	);
};
export default Accordion;

const Styles = styled.div`
	.accordion {
		padding: ${({ theme }) => theme.spacing(1)};
		border: 1px solid ${({ theme }) => grey[400]};
		.accordion-details {
		}
		.accordion-summary {
			.accordion-summary-title {
				font-weight: 700;
			}
		}
		box-shadow: 5px 5px 8px 0px #0000001f;
	}
`;
