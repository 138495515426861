import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faBarsStaggered as fadBarsStaggered,
	faImages as fadImages,
	faMountainSun as fadMountainSun,
	faPieChart as fadPieChart,
	faQrcode as fadQrcode,
	faServer as fadServer,
	faSignsPost as fadSignsPost,
	faTablet as fadTablet,
} from '@fortawesome/pro-duotone-svg-icons';
import {
	faChevronCircleLeft,
	faChevronCircleRight,
	faArrowLeft as fasArrowLeft,
	faCloudUpload as fasCloudUpload,
	faEye as fasEye,
} from '@fortawesome/pro-solid-svg-icons';
import {
	Button,
	CssBaseline,
	Link,
	StyledEngineProvider,
	Typography,
	useTheme,
} from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import CookieConsent from 'react-cookie-consent';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, useLocation, useSearchParams } from 'react-router-dom';
import { Workbox } from 'workbox-window';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import FallbackCircle from '~/components/FallbackCircle';
import RoutesProvider from '~/components/Routes';
import SnackbarProvider from '~/components/Snackbar';
import Theme from '~/components/Theme';
import { Policies } from '~/models/policy';
import { rootRoutes } from '~/routes';
import AuthProvider from '~/utils/auth';
import { PoliciesContext, useRoutes } from '~/utils/contexts';
import globalStyles from './globalStyles';
import { useGet, usePost, usePut } from './utils/api';
import api from './utils/api/endpoints';
import { bakeCookie, readCookie } from './utils/storage';

library.add(
	fasEye,
	fasArrowLeft,
	fadPieChart,
	fadMountainSun,
	fadServer,
	fadTablet,
	fadSignsPost,
	fadQrcode,
	fadImages,
	fasCloudUpload,
	fadBarsStaggered,
	faChevronCircleRight,
	faChevronCircleLeft
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const App = () => {
	const location = useLocation();
	const [params, setParams] = useSearchParams();
	const { data: policies, isLoading } = useGet<Policies>(api.uni.policy);
	const cookie = readCookie('cookie-track');

	const { mutateAsync: updateCookieTrack } = usePut<
		{},
		{
			acceptedCookies?: boolean;
			policyNumber?: string;
			agreedToMarketing?: boolean;
			university?: string;
			email?: string;
			policyId?: string;
			createdAt?: Date;
			type?: string;
		}
	>(api.uni.updateCookieTrack(cookie));

	const { mutateAsync: createCookieTrack, data: acceptedCookieData } = usePost<
		{
			_id: string;
		},
		{
			acceptedCookies: boolean;
			policyNumber: string;
			agreedToMarketing: boolean;
			university: string;
			email: string;
			policyId?: string;
			createdAt: Date;
			type?: string;
		}
	>(api.uni.createCookieTrack);

	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	const match = location.pathname?.split('/').filter((x) => x)?.[0];

	const policy = policies?.results?.find(
		(policy) => policy.name.toLowerCase() === match?.toLocaleLowerCase()
	);

	return (
		<Suspense fallback={<FallbackCircle />}>
			<PoliciesContext.Provider value={{ policies, isLoading, policy }}>
				<Styles>
					{!isLoading && (
						<CookieConsent
							onAccept={async () => {
								if (!!cookie?.length) {
									await updateCookieTrack({
										acceptedCookies: true,
										policyNumber: policy?.policyNumber ?? 'Unknown',
										agreedToMarketing: params.get('marketing') === 'true',
										university: policy?.name ?? 'None',
										email: params.get('email') ?? 'Unknown',
										policyId: policy?._id,
										createdAt: new Date(),
									});
								} else {
									const response = await createCookieTrack({
										acceptedCookies: true,
										policyNumber: policy?.policyNumber ?? 'Unknown',
										agreedToMarketing: params.get('marketing') === 'true',
										university: policy?.name ?? 'None',
										email: params.get('email') ?? 'Unknown',
										policyId: policy?._id,
										createdAt: new Date(),
									});
									bakeCookie('cookie-track', response._id);
								}
							}}
							onDecline={async () => {
								const response = await createCookieTrack({
									acceptedCookies: false,
									policyNumber: policy?.policyNumber ?? 'Unknown',
									agreedToMarketing: params.get('marketing') === 'true',
									university: policy?.name ?? 'None',
									email: params.get('email') ?? 'Unknown',
									policyId: policy?._id,
									createdAt: new Date(),
								});
								bakeCookie('cookie-track', response._id);
							}}
							hideOnAccept
							hideOnDecline
							location="bottom"
							cookieName="cookieConsent"
							style={{
								background: theme.palette.secondary.dark,
								padding: theme.spacing(2),
								justifyContent: 'left',
							}}
							expires={30}
							declineButtonStyle={{
								background: `${theme.palette.error.main}`,
								color: theme.palette.common.white,
							}}
							enableDeclineButton
							declineButtonText="Decline"
							buttonText="Accept"
							buttonStyle={{
								background: theme.palette.secondary.main,
								color: theme.palette.common.white,
							}}
							buttonWrapperClasses="buttons-container"
							buttonClasses="cookie-buttons"
							contentStyle={{
								flexGrow: 'unset',
								flexBasis: undefined,
								flexShrink: undefined,
								textAlign: 'center',
							}}
							flipButtons
							ButtonComponent={(props: {}) => <Button {...props} />}
						>
							<Typography variant="caption" color="white" display="inline">
								This website uses cookies to enhance the user experience. View our{' '}
							</Typography>
							<Link
								href="/cookie-policy"
								sx={{
									color: theme.palette.common.white,
									textDecoration: 'underline',
									fontSize: {
										xs: '1rem',
										md: '1rem',
										lg: '1.2rem',
										xl: '1.5rem',
									},
								}}
							>
								cookie policy
							</Link>
							.
						</CookieConsent>
					)}
				</Styles>
				<Global styles={globalStyles(theme)} />
				{router}
			</PoliciesContext.Provider>
		</Suspense>
	);
};

const Root = () => {
	return (
		<ErrorBoundary
			FallbackComponent={_ErrorBoundary}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}
		>
			<StyledEngineProvider injectFirst>
				<BrowserRouter>
					<Theme>
						<QueryClientProvider client={queryClient}>
							<SnackbarProvider>
								<RoutesProvider routes={rootRoutes}>
									<AuthProvider>
										<CssBaseline />
										<App />
									</AuthProvider>
								</RoutesProvider>
							</SnackbarProvider>
						</QueryClientProvider>
					</Theme>
				</BrowserRouter>
			</StyledEngineProvider>
		</ErrorBoundary>
	);
};

const Styles = styled.div`
	.CookieConsent {
		div {
			${({ theme }) => theme.breakpoints.down('sm')} {
				margin: 15px !important;
				font-size: 18px;
			}
			margin: 0px !important;
			font-size: 24px;
		}

		span {
			${({ theme }) => theme.breakpoints.down('sm')} {
				margin-left: 0px !important;
				margin-bottom: 0px !important;
			}
			margin-left: 15px !important;
			margin-bottom: 8px !important;
		}

		height: 80px;
		display: flex;
		align-items: start !important;
		${({ theme }) => theme.breakpoints.down('md')} {
			padding: 0 !important;
			height: fit-content;
			flex-direction: column;
		}
	}
	.buttons-container {
		${({ theme }) => theme.breakpoints.down('sm')} {
			width: 100%;
			display: flex;
			justify-content: center;
		}
		button {
			margin-top: 0px !important;
		}
	}
`;

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

if ('serviceWorker' in navigator) {
	const wb = new Workbox('/sw.js');

	wb.addEventListener('waiting', (event) => {
		console.log('WAITING');

		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		wb.messageSkipWaiting();

		window.addEventListener('beforeunload', async () => {
			await wb.messageSkipWaiting();
		});
	});

	wb.register();
}
