import { css } from '@emotion/react';
import { darken, Theme } from '@mui/material';
import { pxToRem } from '~/utils/common';

export const IconButtonTransparent = (theme: Theme) => {
	return css`
		.MuiIconButton-root {
			&.MuiIconButton-colorPrimary {
				background: transparent;

				&:hover {
					background-color: ${darken(theme.palette.primary.light, 0.1)};
					color: ${theme.palette.primary.main};
				}
			}

			&.MuiIconButton-colorSecondary {
				background: transparent;

				&:hover {
					background-color: ${darken(theme.palette.secondary.light, 0.1)};
					color: ${theme.palette.secondary.main};
				}
			}

			&.MuiIconButton-colorTertiary {
				background: transparent;

				&:hover {
					background-color: ${darken(theme.palette.tertiary.light, 0.1)};
					color: ${theme.palette.tertiary.main};
				}
			}

			&.MuiIconButton-colorError {
				background: transparent;

				&:hover {
					background-color: ${darken(theme.palette.error.light, 0.1)};
					color: ${theme.palette.error.main};
				}
			}

			&.MuiIconButton-colorSuccess {
				background: transparent;

				&:hover {
					background-color: ${darken(theme.palette.success.light, 0.1)};
					color: ${theme.palette.success.main};
				}
			}

			&.MuiIconButton-colorInfo {
				background: transparent;

				&:hover {
					background-color: ${darken(theme.palette.info.light, 0.1)};
					color: ${theme.palette.info.main};
				}
			}
		}
	`;
};

const IconButton = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiIconButton: {
			defaultProps: {
				color: 'primary',
			},
			styleOverrides: {
				root: css`
					width: 35px;
					height: 35px;
				`,
			},
			variants: [
				{
					props: { color: 'primary' },
					style: css`
						background: ${theme.palette.primary.light};
						color: ${theme.palette.primary.main};
						font-size: ${pxToRem(19)};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.primary.light, 0.1)};
							color: ${theme.palette.primary.main};
						}
					`,
				},
				{
					props: { color: 'secondary' },
					style: css`
						background: ${theme.palette.secondary.light};
						color: ${theme.palette.secondary.main};
						font-size: ${pxToRem(19)};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.secondary.light, 0.1)};
							color: ${theme.palette.secondary.main};
						}
					`,
				},
				{
					props: { color: 'tertiary' },
					style: css`
						background: ${theme.palette.tertiary.light};
						color: ${theme.palette.tertiary.main};
						font-size: ${pxToRem(19)};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.tertiary.light, 0.1)};
							color: ${theme.palette.tertiary.main};
						}
					`,
				},
				{
					props: { color: 'info' },
					style: css`
						background: ${theme.palette.info.light};
						color: ${theme.palette.info.main};
						font-size: ${pxToRem(19)};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.info.light, 0.1)};
							color: ${theme.palette.info.main};
						}
					`,
				},
				{
					props: { color: 'error' },
					style: css`
						background: ${theme.palette.error.light};
						color: ${theme.palette.error.main};
						font-size: ${pxToRem(19)};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.error.light, 0.1)};
							color: ${theme.palette.error.main};
						}
					`,
				},
				{
					props: { color: 'success' },
					style: css`
						background: ${theme.palette.success.light};
						color: ${theme.palette.success.main};
						font-size: ${pxToRem(19)};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.success.light, 0.1)};
							color: ${theme.palette.success.main};
						}
					`,
				},
				{
					props: { size: 'large' },
					style: css`
						font-size: ${pxToRem(22)};
					`,
				},
			],
		},
	};
};

export default IconButton;
