import TextField from './TextField';
import Button from './Button';
import Link from './Link';
import IconButton from '~/theme/components/IconButton';
import { Theme } from '@mui/material';
import merge from 'deepmerge';
import Card from '~/theme/components/Card';
import Container from '~/theme/components/Container';
import DatePicker from "~/theme/components/DatePicker";

const components = (theme: Theme) =>
	merge.all([
		TextField(theme),
		Button(theme),
		Link(theme),
		IconButton(theme),
		Card(theme),
		Container(theme),
		DatePicker(theme),
	]);

export default components;
