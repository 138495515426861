import Info from '~/components/Info';
import Navbar from '~/components/NavBar';

const InfoPage = () => {
	return (
		<div>
			<Navbar variant="info" />
			<Info />
		</div>
	);
};

export default InfoPage;
