import { Theme } from '@mui/material/styles';
import { css } from '@emotion/react';
import { FONT_PRIMARY } from '~/theme/typography';
import { pxToRem } from '~/utils/common';
import { grey } from '@mui/material/colors';
import { transparentize } from 'polished';
import { IconButtonTransparent } from '~/theme/components/IconButton';

const TextField = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiTextField: {
			defaultProps: {
				variant: 'filled',
			},
			variants: [
				{
					props: { variant: 'filled' },
					style: css`
						&.MuiTextField-root {
							.MuiFilledInput-root {
								border-radius: 8px;
								background-color: ${!darkMode
									? theme.palette.common.white
									: theme.palette.background.neutral};

								border: ${theme.palette.primary.main} solid 2px;
								outline: none;
								box-shadow: 4px 4px 0px 0px ${theme.palette.primary.main};

								&::before {
									content: none;
								}

								&.Mui-error:after {
									content: none;
								}

								&.Mui-error {
									background-color: ${!darkMode
										? theme.palette.error.main
										: transparentize(0.5, theme.palette.error.main)};
								}
							}

							.MuiFormHelperText-root.Mui-error {
								color: ${darkMode
									? theme.palette.error.light
									: theme.palette.error.main};
							}

							.MuiFormLabel-root {
								font-family: ${FONT_PRIMARY};
								font-weight: 700;
								opacity: 0.6;
								font-size: ${pxToRem(14)};
								color: ${!darkMode
									? theme.palette.common.black
									: theme.palette.text.primary};

								&.MuiInputLabel-animated {
									//transform: translate(12px, 20px) scale(1);
								}

								&.MuiInputLabel-animated {
									//transform: translate(12px, 20px) scale(1);
								}
								//transform: none;
							}

							.MuiInputBase-root {
								//border: solid 1px #559070;
								//border-bottom-width: 3px;
								//border-right-width: 3px;

								&.Mui-error {
									background: ${darkMode
										? theme.palette.error.darker
										: theme.palette.error.lighter};
								}

								&:hover {
									background-color: ${!darkMode
										? grey[200]
										: transparentize(0.65, theme.palette.common.black)};
								}

								&.Mui-focused,
								&:hover.Mui-focused {
									background-color: ${!darkMode
										? grey[100]
										: transparentize(0.8, theme.palette.common.black)};
								}

								&.Mui-focused {
									&:after {
										content: none;
									}
								}

								.MuiInputBase-input {
									padding: 25px 12px 8px 12px;
								}
							}

							.MuiInputAdornment-root {
								margin-right: -4px;
								${IconButtonTransparent(theme)}
							}
						}
					`,
				},
			],
		},
	};
};

export default TextField;
