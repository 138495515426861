import { css } from '@emotion/react';
import { FONT_PRIMARY, FONT_SECONDARY } from '~/theme/typography';
import { darken, lighten, Theme } from '@mui/material';

const Button = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiButton: {
			styleOverrides: {
				root: css`
					border-radius: 0px;
					font-weight: 700;
					text-transform: none;
					font-family: ${FONT_PRIMARY};
					font-size: 22px;
					padding: ${theme.spacing(0.25, 3)};
					box-shadow: none;

					${theme.breakpoints.up('md')} {
						padding: ${theme.spacing(0.75, 3)};
					}

					&:hover,
					&:focus {
						box-shadow: none;
					}
				`,
			},
			defaultProps: {
				variant: 'contained',
				disableRipple: true,
			},
			variants: [
				{
					props: { variant: 'contained', color: 'primary' },
					style: css`
						color: ${theme.palette.common.white};
						background-color: ${theme.palette.primary.darker};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.primary.darker, 0.3)};
							color: ${theme.palette.primary.lighter};
						}
					`,
				},
				{
					props: { variant: 'contained', color: 'secondary' },
					style: css`
						color: ${theme.palette.common.white};
						background-color: ${theme.palette.secondary.darker};
						border-radius: 0px;

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.secondary.darker, 0.2)};
							color: ${theme.palette.common.white};
						}
					`,
				},
				{
					props: { variant: 'contained', color: 'tertiary' },
					style: css`
						color: ${theme.palette.tertiary.main};
						background-color: ${theme.palette.tertiary.light};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.tertiary.light, 0.1)};
							color: ${theme.palette.tertiary.main};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'primary' },
					style: css`
						color: ${theme.palette.primary.main};
						border: 2px solid ${theme.palette.primary.light};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.primary.light, 0.1)};
							color: ${theme.palette.primary.main};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'secondary' },
					style: css`
						color: ${theme.palette.secondary.main};
						border: 2px solid ${theme.palette.secondary.light};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.secondary.light, 0.1)};
							color: ${theme.palette.secondary.main};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'tertiary' },
					style: css`
						color: ${theme.palette.tertiary.main};
						border: 2px solid ${theme.palette.tertiary.light};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.tertiary.light, 0.1)};
							color: ${theme.palette.tertiary.main};
						}
					`,
				},
			],
		},
	};
};

export default Button;
