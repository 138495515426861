import React, { useState } from 'react';
import {
	Box,
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { FONT_PRIMARY } from '~/theme/typography';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { Policy } from '~/models/policy';

const CoverSummary: React.FC<{ summary?: Policy['coverSummaryInfo'] }> = ({ summary }) => {
	const [showAll, setShowAll] = useState(false);

	const flatSummary = summary?.flatMap?.((section) => section?.items);

	const formattedSummary = showAll ? flatSummary : flatSummary?.slice(0, 4);
	console.log(flatSummary);
	return (
		<Styles style={{ maxWidth: '100%', width: '100%' }}>
			<TableContainer
				sx={(theme) => ({
					width: '100%',
					maxWidth: '100%',
					p: {
						xs: 2,
						sm: 4,
						md: 6,
						lg: 8,
					},
					borderColor: theme.palette.primary.dark,
					borderWidth: 5,
					borderRadius: 2,
					borderStyle: 'solid',
				})}
			>
				<Table
					className="cover-summary-table"
					sx={{ background: 'white', boxShadow: 'none', width: '100%', maxWidth: '100%' }}
				>
					<TableHead>
						<TableRow>
							<TableCell
								className="header"
								sx={{ p: '2px !important', borderRight: '3px solid #0d223d' }}
							>
								<Typography
									sx={{
										px: { xs: 0.5, sm: 2, md: 4 },
										fontWeight: 800,
										whiteSpace: 'nowrap',
									}}
									variant="subtitle1"
									component="p"
								>
									What’s Covered
								</Typography>
							</TableCell>
							<TableCell
								className=""
								sx={{
									whiteSpace: 'nowrap',
									p: { xs: '0px !important', sm: '16px !important' },
								}}
							>
								<Typography
									sx={{
										px: { xs: 0.5, sm: 2, md: 4 },
										fontWeight: 800,
										whiteSpace: 'nowrap',
									}}
									variant="subtitle1"
									component="p"
								>
									Sum Insured
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{formattedSummary?.map((item, idx) => (
							<TableRow key={idx}>
								<TableCell
									className="border-right"
									sx={{
										textOverflow: 'ellipsis',
										overflow: 'auto',
										pr: { xs: '6px !important', sm: '16px !important' },
										pl: { xs: '6px !important', sm: '16px !important' },
										borderBottom: {
											xs:
												idx === formattedSummary.length - 1
													? 'none !important'
													: '3px solid #0d223d',
											sm: '3px solid #0d223d !important',
										},
									}}
								>
									<Typography
										sx={{
											px: { xs: 0, sm: 2, md: 4 },
											py: 1.5,
										}}
										variant="body2"
										component="p"
									>
										{item.title}
									</Typography>
								</TableCell>
								<TableCell
									sx={{
										borderBottom: {
											xs:
												idx === formattedSummary.length - 1
													? 'none !important'
													: '3px solid #0d223d',
											sm: '3px solid #0d223d !important',
										},
									}}
								>
									<Typography
										sx={{
											px: { xs: 0, sm: 2, md: 4 },
											py: 1,
											fontWeight: 700,
										}}
										variant="body2"
										component="p"
									>
										{item.sumInsured[0]}
									</Typography>
								</TableCell>
							</TableRow>
						))}

						<TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
							<TableCell
								sx={{ borderBottom: 'none !important' }}
								className="border-right"
							>
								<Typography
									component="button"
									sx={{
										bgcolor: 'transparent',
										px: { xs: 0, sm: 2, md: 4 },
										cursor: 'pointer',
										py: 1.5,
										fontWeight: 700,
										whiteSpace: 'nowrap',
										fontSize: {
											xs: '16px',
											md: '28px',
										},
										':hover img': {
											cursor: 'pointer',
											transform: 'translateY(5px)',
										},
										display: 'flex',
										alignItems: 'center',
										gap: 1,
									}}
									variant="h5"
									onClick={() => {
										console.log('clicked', showAll);
										setShowAll((prev) => !prev);
									}}
								>
									{showAll ? 'Hide Full Cover Limits' : 'See Full Cover Limits'}
									<Box
										sx={{
											transition: 'all 0.3s ease-in-out',
											rotate: showAll ? '180deg' : '0deg',
										}}
										component="img"
										src="/down-arrow.svg"
										width={25}
										height={35}
									/>
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				<Typography
					component="button"
					sx={{
						textAlign: 'center',
						mx: 'auto',
						bgcolor: 'transparent',
						px: { xs: 0, sm: 2, md: 4 },
						cursor: 'pointer',
						py: 1.5,
						fontWeight: 700,
						whiteSpace: 'nowrap',
						fontSize: {
							xs: '16px',
							md: '28px',
						},
						':hover img': {
							cursor: 'pointer',
							transform: 'translateY(5px)',
						},
						display: { xs: 'flex', sm: 'none' },
						alignItems: 'center',
						gap: 1,
					}}
					variant="h5"
					onClick={() => {
						console.log('clicked', showAll);
						setShowAll((prev) => !prev);
					}}
				>
					{showAll ? 'Hide Full Cover Limits' : 'See Full Cover Limits'}
					<Box
						sx={{
							transition: 'all 0.3s ease-in-out',
							rotate: showAll ? '180deg' : '0deg',
						}}
						component="img"
						src="/down-arrow.svg"
						width={25}
						height={35}
					/>
				</Typography>
			</TableContainer>
		</Styles>
	);
};

const Styles = styled.div`
	* {
		font-family: ${FONT_PRIMARY} !important;
	}

	.cover-summary-table {
		overflow: hidden;
	}

	th,
	td {
		border-bottom: 3px solid #0d223d;
		font-size: 16px;
		color: #0d223d;
	}

	.header {
		font-weight: bold;
		background: white;
	}

	.right {
		text-align: right;
	}

	.border-right {
		border-right: 3px solid #0d223d;
	}
`;

export default CoverSummary;
