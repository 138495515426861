import React, { Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import {
	BrowserRouter,
	matchPath,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import {
	CircularProgress,
	CssBaseline,
	ThemeProvider,
	useTheme,
	Button,
	Link,
	useThemeProps,
	Box,
} from '@mui/material';
import SnackbarProvider, { useSnackbar } from '~/components/Snackbar';
import { Workbox } from 'workbox-window';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import Theme from '~/components/Theme';
import { rootRoutes } from '~/routes';
import { PoliciesContext, useRoutes } from '~/utils/contexts';

import {
	faEye as fasEye,
	faArrowLeft as fasArrowLeft,
	faCloudUpload as fasCloudUpload,
	faArrowCircleRight,
	faArrowCircleLeft,
	faChevronCircleRight,
	faChevronCircleLeft,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faPieChart as fadPieChart,
	faMountainSun as fadMountainSun,
	faServer as fadServer,
	faTablet as fadTablet,
	faSignsPost as fadSignsPost,
	faQrcode as fadQrcode,
	faImages as fadImages,
	faBarsStaggered as fadBarsStaggered,
} from '@fortawesome/pro-duotone-svg-icons';
import globalStyles from './globalStyles';
import RoutesProvider from '~/components/Routes';
import AuthProvider from '~/utils/auth';
import FallbackCircle from '~/components/FallbackCircle';
import CookieConsent from 'react-cookie-consent';
import styled from '@emotion/styled';
import { useGet, usePost } from './utils/api';
import api from './utils/api/endpoints';
import { Policies, Policy } from '~/models/policy';

library.add(
	fasEye,
	fasArrowLeft,
	fadPieChart,
	fadMountainSun,
	fadServer,
	fadTablet,
	fadSignsPost,
	fadQrcode,
	fadImages,
	fasCloudUpload,
	fadBarsStaggered,
	faChevronCircleRight,
	faChevronCircleLeft
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const App = () => {
	const location = useLocation();
	const [params, setParams] = useSearchParams();
	const { data: policies, isLoading } = useGet<Policies>(api.uni.policy);
	const { mutateAsync: createCookieTrack } = usePost<
		{},
		{
			accepted: boolean;
			policyNumber: string;
			university: string;
			email: string;
			policyId?: string;
		}
	>(api.uni.createCookieTrack);
	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	const match = matchPath({ path: '/:policyName' }, location.pathname);
	const policyNameParam = match?.params?.policyName;

	const policy = policies?.results?.find(
		(policy) => policy.name.toLowerCase() === policyNameParam?.toLocaleLowerCase()
	);

	return (
		<Suspense fallback={<FallbackCircle />}>
			<PoliciesContext.Provider value={{ policies, isLoading, policy }}>
				<Styles>
					{!isLoading && (
						<CookieConsent
							onAccept={async () => {
								await createCookieTrack({
									accepted: true,
									policyNumber: policy?.policyNumber ?? 'Unknown',
									university: policy?.name ?? 'None',
									email: params.get('email') ?? 'Unknown',
									policyId: policy?._id,
								});
							}}
							onDecline={async () => {
								await createCookieTrack({
									accepted: false,
									policyNumber: policy?.policyNumber ?? 'Unknown',
									university: policy?.name ?? 'None',
									email: params.get('email') ?? 'Unknown',
									policyId: policy?._id,
								});
							}}
							hideOnAccept
							hideOnDecline
							location="bottom"
							cookieName="cookieConsent"
							style={{
								background: theme.palette.secondary.dark,
								padding: theme.spacing(2),
								justifyContent: 'left',
							}}
							expires={30}
							declineButtonStyle={{
								background: theme.palette.error.main,
								color: theme.palette.common.white,
							}}
							enableDeclineButton
							declineButtonText="Decline"
							buttonText="Accept"
							buttonStyle={{
								background: theme.palette.secondary.main,
								color: theme.palette.common.white,
							}}
							buttonWrapperClasses="buttons-container"
							buttonClasses="cookie-buttons"
							contentStyle={{
								flexGrow: 'unset',
								flexBasis: undefined,
								flexShrink: undefined,
								textAlign: 'center',
							}}
							flipButtons
							ButtonComponent={(props: {}) => <Button {...props} />}
						>
							This website uses cookies to enhance the user experience. View our{' '}
							<Link
								href="/cookie-policy"
								sx={{
									color: theme.palette.common.white,
									textDecoration: 'underline',
								}}
							>
								cookie policy
							</Link>
							.
						</CookieConsent>
					)}
				</Styles>
				<Global styles={globalStyles(theme)} />
				{router}
			</PoliciesContext.Provider>
		</Suspense>
	);
};

const Root = () => {
	return (
		<ErrorBoundary
			FallbackComponent={_ErrorBoundary}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}
		>
			<BrowserRouter>
				<Theme>
					<QueryClientProvider client={queryClient}>
						<SnackbarProvider>
							<RoutesProvider routes={rootRoutes}>
								<AuthProvider>
									<CssBaseline />
									<App />
								</AuthProvider>
							</RoutesProvider>
						</SnackbarProvider>
					</QueryClientProvider>
				</Theme>
			</BrowserRouter>
		</ErrorBoundary>
	);
};

const Styles = styled.div`
	.CookieConsent {
		height: 80px;
		display: flex;
		align-items: start !important;
		${({ theme }) => theme.breakpoints.down('md')} {
			padding: 0 !important;
			height: fit-content;
			flex-direction: column;
		}
	}
	.buttons-container {
		${({ theme }) => theme.breakpoints.down('sm')} {
			width: 100%;
			display: flex;
			justify-content: center;
		}
		button {
			margin-top: 0px !important;
		}
	}
`;

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

if ('serviceWorker' in navigator) {
	const wb = new Workbox('/sw.js');

	wb.addEventListener('waiting', (event) => {
		console.log('WAITING');

		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		wb.messageSkipWaiting();

		window.addEventListener('beforeunload', async () => {
			await wb.messageSkipWaiting();
		});
	});

	wb.register();
}
