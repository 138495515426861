import React from 'react';
import styled from '@emotion/styled';
import { Box, Link, Stack } from '@mui/material';
import QRCode from 'react-qr-code';
import { IUniMember } from '~/models/uniMember';
import api from '~/utils/api';
import { Policy } from '~/models/policy';
interface IWalletButtonsProps {
	policy: Policy;
}

const WalletButtons: React.FC<IWalletButtonsProps> = (props) => {
	return (
		<Styles>
			<Stack sx={{ mt: '16px' }} direction="row" spacing={3} justifyContent="center">
				<Stack direction="row" className={'wallet-stack'} justifyContent="center">
					<Stack
						direction={'row'}
						spacing={2}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Box>
							<QRCode
								className={'qr'}
								size={100}
								value={`${api.baseURL}/l/f/${props?.policy?.wallet?.apple?.short}`}
							/>
						</Box>

						<Box sx={{ position: 'relative' }}>
							<Link
								href={`https://arma-karma.s3.eu-west-2.amazonaws.com/${props?.policy?.wallet?.apple?.awsKey}`}
								sx={{
									display: 'block',
									top: '0px',
									left: '0px',
									zIndex: 5,
									position: 'absolute',
									height: '100%',
									width: '100%',
								}}
								target="_blank"
							></Link>
							<object
								data="/assets/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"
								type="image/svg+xml"
								className="info-wallet-grid-img apple"
							></object>
						</Box>
					</Stack>
					<Stack direction={'row'} spacing={2}>
						<Box>
							<QRCode
								className={'qr'}
								size={100}
								value={`${api.baseURL}/l/f/${props?.policy?.wallet?.google?.short}`}
							/>
						</Box>

						<Link
							href={`https://pay.google.com/gp/v/save/${props?.policy?.wallet?.google?.token}`}
							sx={{
								cursor: 'pointer',
							}}
							target="_blank"
						>
							<img
								src="/assets/google-wallet.svg"
								className="info-wallet-grid-img google"
							/>
						</Link>
					</Stack>
				</Stack>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.wallet-stack {
		gap: ${({ theme }) => theme.spacing(4)};
		${({ theme }) => theme.breakpoints.down('md')} {
			gap: ${({ theme }) => theme.spacing(1)};
		}
	}
	.qr {
		border: 8px solid white;
		${({ theme }) => theme.breakpoints.down('sm')} {
			display: none;
		}
		path {
		}
	}
	.google {
		width: 196px;
		height: 100%;
		transform: translateY(-4px);
		${({ theme }) => theme.breakpoints.down('md')} {
			width: 200px;
		}
		${({ theme }) => theme.breakpoints.down('sm')} {
			width: 160px;
		}
	}
	.apple {
		cursor: pointer;
		transform: translate3d(0, 0, 0);
		height: 100%;
		${({ theme }) => theme.breakpoints.down('md')} {
			width: 175px;
		}
		${({ theme }) => theme.breakpoints.down('sm')} {
			width: 140px;
		}
		width: 196px;
	}
`;

export default WalletButtons;
