import styled from '@emotion/styled';
import {
	Button,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import { FONT_PRIMARY } from '~/theme/typography';

const WhatsNotCovered: React.FC = () => {
	const tableData = [
		{
			title: 'Your possessions outside your Accommodation against theft, loss or damage',
		},
		{
			title: 'Accidental damage to your phone and gadgets',
		},
		{
			title: 'Worldwide cover for your gadgets abroad',
		},
	];

	return (
		<Styles style={{ maxWidth: '100%', width: '100%' }}>
			<TableContainer
				sx={(theme) => ({
					width: '100%',
					maxWidth: '100%',
					p: {
						xs: 2,
						sm: 4,
						md: 6,
						lg: 8,
					},
					borderColor: theme.palette.primary.dark,
					borderWidth: 5,
					borderRadius: 2,
					borderStyle: 'solid',
				})}
			>
				<Table
					className="cover-summary-table"
					sx={{ background: 'white', boxShadow: 'none', width: '100%', maxWidth: '100%' }}
				>
					<TableHead>
						<TableRow>
							<TableCell
								className="header"
								sx={{
									p: '2px !important',
									borderRight: '3px solid #0d223d',
								}}
							>
								<Typography
									sx={{
										px: { xs: 0.5, sm: 2, md: 4 },
										fontWeight: 800,
										whiteSpace: 'nowrap',
									}}
									variant="subtitle1"
									component="p"
								>
									What’s Not Covered
								</Typography>
							</TableCell>
							<TableCell
								className=""
								sx={{
									whiteSpace: 'nowrap',
									p: { xs: '0px !important', sm: '24px !important' },
								}}
							>
								<a href="#upgrade">
									<Button
										sx={(theme) => ({
											color: 'white !important',
											display: { xs: 'none', sm: 'block' },
											backgroundColor: `${theme.palette.secondary.light} !important`,
										})}
										color="tertiary"
									>
										Upgrade your Cover
									</Button>
								</a>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableData?.map((item, idx) => (
							<TableRow key={idx}>
								<TableCell
									className="border-right"
									sx={{
										textOverflow: 'ellipsis',
										overflow: 'auto',
										pr: { xs: '6px !important', sm: '16px !important' },
										pl: { xs: '6px !important', sm: '16px !important' },
										borderBottom: {
											xs:
												idx === tableData.length - 1
													? 'none !important'
													: '3px solid #0d223d',
										},
									}}
								>
									<Typography
										sx={{
											px: { xs: 0, sm: 2, md: 4 },
											py: 1.5,
										}}
										variant="body2"
										component="p"
									>
										{item.title}
									</Typography>
								</TableCell>
								<TableCell
									sx={{
										borderBottom: {
											xs:
												idx === tableData.length - 1
													? 'none !important'
													: '3px solid #0d223d',
										},
									}}
								>
									<Stack
										sx={{ pl: { xs: 0, sm: 2, md: 4 } }}
										direction={{ xs: 'column', sm: 'row' }}
										alignItems="center"
										justifyContent="center"
									>
										<Stack
											alignItems="center"
											justifyContent="center"
											sx={{
												img: {
													height: {
														xs: '18px',
														sm: '24px',
														md: '30px',
													},
												},
											}}
										>
											<img
												src="/assets/close.svg"
												alt="close"
												width={30}
												height={30}
											/>
										</Stack>
										<Typography
											sx={{
												px: { xs: 0, sm: 2, md: 4 },
												py: 1,
												textAlign: 'center',
												fontWeight: 700,
												whiteSpace: 'nowrap',

												textDecoration: 'underline',
												':hover': {
													textDecoration: 'none !important',
												},
											}}
											variant="caption"
											component="a"
											href="#upgrade"
										>
											Learn more
										</Typography>
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<a href="#upgrade">
					<Button
						sx={(theme) => ({
							mx: 'auto',
							color: 'white !important',
							display: { xs: 'block', sm: 'none' },
							py: 1,
							mt: 2,
							backgroundColor: `${theme.palette.secondary.light} !important`,
						})}
						color="tertiary"
					>
						Upgrade your Cover
					</Button>
				</a>
			</TableContainer>
		</Styles>
	);
};

const Styles = styled.div`
	* {
		font-family: ${FONT_PRIMARY} !important;
	}

	.cover-summary-table {
		overflow: hidden;
	}

	th,
	td {
		border-bottom: 3px solid #0d223d;
		font-size: 16px;
		color: #0d223d;
	}

	.header {
		font-weight: bold;
		background: white;
	}

	.right {
		text-align: right;
	}

	.border-right {
		border-right: 3px solid #0d223d;
	}
`;

export default WhatsNotCovered;
