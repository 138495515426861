import { Theme } from '@mui/material/styles';
import { css } from '@emotion/react';
import { FONT_SECONDARY } from '~/theme/typography';

const TextField = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiLink: {
			styleOverrides: {
				root: css`
					color: ${!darkMode ? theme.palette.primary.main : theme.palette.primary.light};
					text-decoration: none;
					font-family: ${FONT_SECONDARY};

					&:hover {
						text-decoration-color: ${theme.palette.primary.dark};
					}
				`,
			},
		},
	};
};

export default TextField;
