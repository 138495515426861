import React, { lazy } from 'react';
import type { PreRoute } from '~/models/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Info from '~/pages/info';

const Home = lazy(() => import('../pages/index'));
const Offline = lazy(() => import('../pages/offline'));
const Private = lazy(() => import('../components/Private'));
const CookiePolicy = lazy(() => import('../pages/cookiePolicy'));

const routes: PreRoute[] = [
	{
		path: '/',
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: '/:university',

				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: '/:university/info',
						element: <Info />,
					},
				],
			},

			{
				path: '/offline',
				element: <Offline />,
			},
			{
				path: '/cookie-policy',
				element: <CookiePolicy />,
			},
		],
	},
	{
		element: <Private />,
		children: [],
	},
];

export const navigation = [
	{
		name: 'Home',
		path: '/',
		icon: <FontAwesomeIcon icon={['fad', 'pie-chart']} />,
	},
];

export default routes;
