import React from 'react';
import {
	AccordionSummary,
	Accordion,
	Button,
	Grid,
	Typography,
	AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Homepage from '~/components/Homepage';
import Info from '~/components/Info';
import Navbar from '~/components/NavBar';

const InfoPage = () => {
	return (
		<>
			<Navbar variant="info" />
			<Info />
		</>
	);
};

export default InfoPage;
