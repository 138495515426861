import styled from '@emotion/styled';
import {
	Accordion as MuiAccordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
	Skeleton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import { faqArma } from '~/helpers/faqData';
import Accordion from '~/components/Accordion';

interface FrequentlyAskedQuestionsProps {
	isLoading?: boolean;
	faqs: {
		summary: React.ReactNode;
		details: React.ReactNode;
		steps?: React.ReactNode[];
	}[];
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = (props) => {
	if (props.isLoading) {
		return <Skeleton variant="rectangular" height={208} width="100%" />;
	}
	return (
		<Styles>
			<Grid container spacing={2}>
				{props?.faqs?.map?.((faq, index) => {
					if (!faq) return null;
					return (
						<>
							<Accordion data={faq} />
						</>
					);
				})}
			</Grid>
		</Styles>
	);
};
export default FrequentlyAskedQuestions;

const Styles = styled.div`
	.accordion {
		border: 1px solid ${({ theme }) => grey[400]};
		.accordion-details {
		}
		.accordion-summary {
			.accordion-summary-title {
				font-weight: 700;
			}
		}
	}
`;
