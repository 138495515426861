import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo } from 'react';
import { RoutesContext } from '~/utils/contexts';
import { RouteObject } from 'react-router/dist/lib/context';
import { useLocation, useMatch } from 'react-router-dom';
import { Navigation, NavigationPreRoute, PreRoute, Route, Routes } from '~/models/route';
import { useRoutes, generatePath, matchRoutes } from 'react-router-dom';

const routeMatches = (route: Route | undefined) => {
	if (!route) return false;
	if (route?.match) return true;
	if (route?.children?.some(routeMatches)) return true;
};

const useFlatRoutes = (routes: Route[]): Route[] => {
	return useMemo(() => {
		const flattenNavigationItems = (items: Route[] = routes): Route[] => {
			const flattenNavigationItem = (item: Route) => {
				// to make TS happy
				if (!item) return [];
				if (!item?.children?.length) return [item];

				return [item, ...flattenNavigationItems(item.children)];
			};

			return items.reduce((acc: Route[], curr) => [...acc, ...flattenNavigationItem(curr)], []);
		};

		return flattenNavigationItems();
	}, []);
};

const useRouteStack = (routes: Route[]) => {
	return useMemo(() => {
		const createRouteStack = (items: Route[] = routes): any => {
			const routeStack = items?.find((item) => routeMatches(item));

			if (routeStack?.children) {
				return [routeStack, ...createRouteStack(routeStack?.children)]?.filter((x) => !!x);
			}

			if (!!routeStack) return [routeStack];

			return [];
		};

		return createRouteStack();
	}, [routes]);
};

const convert = (item: PreRoute, parentPath?: string | null, parent?: PreRoute): Route => {
	const formatPath = (route: PreRoute) => {
		const currentPath = item?.index ? parent?.path : item?.path;
		return (parentPath ? `${parentPath}${currentPath ?? ''}` : currentPath ?? '') ?? '';
	};

	const checkMatch = (route: PreRoute, root = true): { match: boolean; rootMatch: boolean } => {
		const path = formatPath(route);
		const match = !!path ? !!useMatch(path) : false;

		return {
			match: (route?.children?.map((r) => checkMatch(r, false)?.match)?.some((x) => !!x) ?? false) || match,
			rootMatch: root && match,
		};
	};

	const matches = checkMatch(item);

	return {
		...item,
		path: formatPath(item),
		...matches,
		children: !!item?.children?.length ? item.children.map((x) => convert(x, parentPath, parent)) : undefined,
	};
};

const routerConvert = (item: PreRoute): RouteObject => {
	const route = { ...item };
	delete route.name;
	delete route.sidebar;
	if (route.children) route.children.map(routerConvert);
	return route;
};

const useRouter = (routes: RouteObject[]) => useRoutes(routes);

const RoutesProvider: React.FC<{ routes: PreRoute[]; navigation?: NavigationPreRoute[]; children: React.ReactNode }> = ({ children, routes: _routes, navigation: _navigation }) => {
	const { currentRouteItem: parentRouteItem } = useContext(RoutesContext);
	const parent = parentRouteItem?.path ? generatePath(parentRouteItem?.path) : null;
	const navigation = (_navigation?.map((x) => convert(x)) ?? []) as Navigation;
	const routes = _routes.map((x) => convert(x, parent)) as Routes;
	const routerRoutes = _routes.map(routerConvert);
	const flatRoutes = useFlatRoutes(routes);
	const routeStack = useRouteStack(routes);
	const currentRouteItem = routeStack?.[routeStack?.length - 1];
	const router = useRouter(routerRoutes);

	return (
		<RoutesContext.Provider
			value={{
				navigation,
				routes,
				routerRoutes,
				flatRoutes,
				routeStack,
				router,
				currentRouteItem,
			}}
		>
			{children}
		</RoutesContext.Provider>
	);
};

export default RoutesProvider;