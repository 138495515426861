import React from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import { IUniMember } from '~/models/uniMember';
import api from '~/utils/api';
import { Policy } from '~/models/policy';
interface IWalletButtonsProps {
	policy: Policy;
}

const WalletButtons: React.FC<IWalletButtonsProps> = (props) => {
	return (
		<Box>
			<Typography
				sx={{ display: { xs: 'block', lg: 'none' } }}
				variant="body2"
				color="white"
				textAlign="center"
				mt={2}
				mb={2}
			>
				Add your Policy straight to your digital wallet!
			</Typography>
			<Grid container spacing={{ xs: 0, md: 4 }}>
				<Grid item xs={12} lg={6}>
					<Box
						sx={(theme) => ({
							borderRadius: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							p: { xs: 0, lg: 4 },
							bgcolor: { xs: 'none', lg: theme.palette.common.white },
							position: 'relative',
							maxWidth: '100%',
							width: '100%',
						})}
					>
						<Box
							maxWidth="100%"
							sx={{
								svg: { maxWidth: '100%' },
								display: { xs: 'none', lg: 'block' },
							}}
						>
							<QRCode
								className={'qr'}
								size={150}
								value={`${api.baseURL}/l/f/${props?.policy?.wallet?.apple?.short}`}
							/>
						</Box>
						<Link
							href={`https://arma-karma.s3.eu-west-2.amazonaws.com/${props?.policy?.wallet?.apple?.awsKey}`}
							sx={{
								display: 'block',
								top: '0px',
								left: '0px',
								zIndex: 5,
								position: 'absolute',
								height: '100%',
								width: '100%',
								img: {
									width: '200px',
								},
							}}
							target="_blank"
						></Link>
						<Box
							sx={{
								object: {
									mt: { xs: 3, lg: 2 },
									maxWidth: '100%',
									minWidth: { xs: '200px', lg: '180px' },
									height: { xs: '56px', lg: '50px' },
								},
							}}
						>
							<object
								data="/assets/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"
								type="image/svg+xml"
							></object>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} lg={6}>
					<Box
						sx={(theme) => ({
							mt: { xs: -3, md: -10, lg: 0 },
							borderRadius: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							p: { xs: 2, sm: 2, md: 4 },
							bgcolor: { xs: 'none', lg: theme.palette.common.white },
							position: 'relative',
							maxWidth: '100%',
							width: '100%',
							img: {
								maxWidth: '100%',
								width: '100%',
							},
						})}
					>
						<Box
							maxWidth="100%"
							sx={{
								svg: { maxWidth: '100%', display: { xs: 'none', lg: 'block' } },
							}}
						>
							<QRCode
								className={'qr'}
								size={150}
								value={`${api.baseURL}/l/f/${props?.policy?.wallet?.google?.short}`}
							/>
						</Box>

						<Box>
							<Link
								href={`https://pay.google.com/gp/v/save/${props?.policy?.wallet?.google?.token}`}
								sx={{
									cursor: 'pointer',
									img: {
										mt: 3,
										maxWidth: '100%',
										minWidth: { xs: '200px', lg: '180px' },
										height: '50px',
									},
								}}
								target="_blank"
							>
								<img src="/assets/google-wallet.svg" />
							</Link>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Typography
				sx={{ display: { xs: 'none', lg: 'block' } }}
				variant="body2"
				color="white"
				textAlign="center"
				mt={4}
			>
				Add your Policy straight to your digital wallet!
			</Typography>
		</Box>
	);
};

const Styles = styled.div`
	.wallet-stack {
		gap: ${({ theme }) => theme.spacing(4)};
		${({ theme }) => theme.breakpoints.down('md')} {
			gap: ${({ theme }) => theme.spacing(1)};
		}
	}
	.qr {
		border: 8px solid white;
		${({ theme }) => theme.breakpoints.down('sm')} {
			display: none;
		}
		path {
		}
	}
	.google {
		width: 196px;
		height: 100%;
		transform: translateY(-4px);
		${({ theme }) => theme.breakpoints.down('md')} {
			width: 200px;
		}
		${({ theme }) => theme.breakpoints.down('sm')} {
			width: 160px;
		}
	}
	.apple {
		cursor: pointer;
		transform: translate3d(0, 0, 0);
		height: 100%;
		${({ theme }) => theme.breakpoints.down('md')} {
			width: 175px;
		}
		${({ theme }) => theme.breakpoints.down('sm')} {
			width: 140px;
		}
		width: 196px;
	}
`;

export default WalletButtons;
