import styled from '@emotion/styled';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	FormControl,
	Grid,
	Input,
	Link,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useParams, useSearchParams } from 'react-router-dom';
import Footer from '~/components/Footer';
import CoverSummary from '~/components/Info/CoverSummary';
import RedirectDialog from '~/components/RedirectDialog';
import WalletButtons from '~/components/WalletButtons';
import { Policy } from '~/models/policy';
import api, { useGet, usePut } from '~/utils/api';
import { pxToRem } from '~/utils/common';
import { readCookie } from '~/utils/storage';
import Faqs from '../Homepage/Faqs';
import WhatsNotCovered from './WhatsNotCovered';
import faqGallagher, { faqFreeCover } from '~/helpers/faqData';

const Info: React.FC<{}> = () => {
	const { data: policies, isLoading } = useGet<{
		results: Policy[];
	}>(api.uni.policy);

	const { university } = useParams();

	const policy = policies?.results.find(
		(policy) => policy.name.toLowerCase() === university?.toLowerCase()
	);

	const faqs = faqFreeCover(policy?.disableFree ?? false, policy?.policyNumber ?? '');

	const { params } = useParams();
	const [open, setOpen] = React.useState(false);
	const [redirectUrl, setRedirectUrl] = React.useState('');
	const [coverInfoOpen, setCoverInfoOpen] = useState(false);

	useEffect(() => {
		if (policy?.disableFree) {
			setCoverInfoOpen(true);
		}
	}, [policy]);
	const policyShortLink = policy?.name ? policy.name.toLowerCase() : 'policy';

	//Use query search params under ?policy=[policyNumber] to find the university you want.
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentSlide, setCurrentSlide] = useState(0);
	const subject = `Policy Number: ${policy?.policyNumber}`;
	const body = `Hi, My policy number is ${
		policy?.policyNumber
	}\n\nTo download my policy documents, [click here](${policy?.wordingPDF || ''}).`;

	useEffect(() => {
		flushSync(() => {
			if (location.hash) {
				const hash = location.hash.split('#')[1];
				const element = document.getElementById(hash);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
				}
			}
		});
	}, [location?.hash]);

	const isDownloadable = searchParams.get('download') === 'true';

	useEffect(() => {
		if (policy?.policyNumber) {
			setSearchParams((prevParams) => {
				prevParams.set('policyNumber', policy?.policyNumber ?? '');
				return prevParams;
			});
		}
		if (!isDownloadable) return;
		// Remove automaticaly downloading the policy PDF document as this will (by default) open the downloaded file in a
		// new tab.

		// if (policy) {
		// 	// fetch a blob of the PDF as the download attribute ignores files origiating from another site. Constructs an
		// 	// anchor link and clicks it. Default behaviour for most browsers is to automaticaly open PDFS so this may still
		// 	// look like this is opening in a new tab. But it will be saved locally.
		// 	fetch(policy.wordingPDF)
		// 		.then((response) => response.blob())
		// 		.then((blob) => {
		// 			const url = window.URL.createObjectURL(blob);
		// 			const a = document.createElement('a');
		// 			a.style.display = 'none';
		// 			a.href = url;
		// 			a.download = 'Group Possessions Policy Wording.pdf';
		// 			document.body.appendChild(a);
		// 			a.click();
		// 			window.URL.revokeObjectURL(url);
		// 			document.body.removeChild(a);
		// 		})
		// 		.catch(() => alert('An error occurred while downloading the file.'));
		// 	setSearchParams((prev) => {
		// 		prev.delete('download');
		// 		return prev;
		// 	});
		// }
	}, [policy, policies]);

	const cookie = readCookie('cookie-track');

	const [email, setEmail] = useState(searchParams?.get?.('email') ?? '');

	const {
		mutateAsync: updateCookieTrack,
		isLoading: isEnteringGiveaway,
		isSuccess,
	} = usePut<
		{},
		{
			acceptedCookies?: boolean;
			policyNumber?: string;
			agreedToMarketing?: boolean;
			university?: string;
			email?: string;
			policyId?: string;
			createdAt?: Date;
			type?: string;
			notes?: string;
		}
	>(api.uni.updateCookieTrack(cookie));

	return (
		<Styles>
			<RedirectDialog
				setOpen={setOpen}
				newTab={true}
				link={!!redirectUrl ? redirectUrl : 'https://armakarma.insure/claims/'}
				open={open}
				handleClose={() => setOpen(false)}
			/>

			<Box
				sx={(theme) => ({
					background: theme.palette.primary.main,
					px: { xs: 0, sm: 2, md: 4, lg: 6 },
				})}
			>
				<Container
					maxWidth="xl"
					sx={{ display: 'flex', flexDirection: 'column', gap: 6, mb: 14 }}
				>
					{/* -----------YOUR COVER AWAITS CARD----------- */}

					<Box
						sx={(theme) => ({
							position: 'relative',
							boxShadow: `15px 15px 0px 0px ${theme.palette.primary.dark}`,
							background:
								'radial-gradient(28.28% 66.84% at 2.85% 3.2%, rgba(0, 117, 188, 0.3) 0%, rgba(0, 117, 188, 0.1) 34%, rgba(255, 255, 255, 0) 100%), radial-gradient(31.97% 75.5% at 97.89% 90.33%, rgba(0, 117, 188, 0.3) 0%, rgba(0, 117, 188, 0.1) 34%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
							// background:
							// 'radial-gradient(28.28% 66.84% at 2.85% 3.2%, rgba(0, 117, 188, 0.5) 0%, rgba(0, 117, 188, 0.3) 34%, rgba(255, 255, 255, 0) 100%), radial-gradient(31.97% 75.5% at 97.89% 90.33%, rgba(0, 117, 188, 0.5) 0%, rgba(0, 117, 188, 0.3) 34%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #00263E, #00263E)',
							borderColor: theme.palette.primary.dark,
							borderWidth: 5,
							borderRadius: 2,
							borderStyle: 'solid',
							px: { xs: 3, sm: 6, md: 8, lg: 10 },
							py: { xs: 4, sm: 6, md: 12, lg: 14 },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'start',
							maxWidth: '99%',
						})}
					>
						{!!policy?.logo && (
							<Box
								sx={(theme) => ({
									display: { xs: 'flex', lg: 'none' },
									width: '100%',
									justifyContent: 'end',
									mb: 4,

									color: theme.palette.secondary.main,
									img: {
										bgcolor: theme.palette.grey[400],
										p: 1,
										borderRadius: 1,
										height: 'auto',
										maxHeight: { xs: 100, sm: 125, md: 150 },
										width: { xs: 150, sm: 200, md: 250, lg: 300 },
									},
								})}
							>
								<img src={policy?.logo} width={300} />
							</Box>
						)}
						<Typography
							component="h1"
							variant="h2"
							sx={(theme) => ({
								color: theme.palette.primary.dark,
							})}
						>
							Welcome To The Hub!
						</Typography>

						<Typography
							variant="subtitle1"
							sx={(theme) => ({
								fontWeight: 700,
								color: theme.palette.primary.dark,
							})}
						>
							Your Policy Number:{' '}
							<Box
								component="span"
								sx={(theme) => ({
									color: theme.palette.secondary.main,
								})}
							>
								{isLoading ? (
									<Skeleton
										sx={{ display: 'inline-block' }}
										width={150}
										height={45}
										variant="text"
									/>
								) : (
									policy?.policyNumber
								)}
							</Box>
						</Typography>

						<Stack
							direction="row"
							flexWrap="wrap"
							gap={{ xs: 1, sm: 2 }}
							sx={{ mt: 3 }}
						>
							<Button
								sx={(theme) => ({
									bgcolor: `${theme.palette.secondary.main} !important`,
									color: `${theme.palette.common.white} !important`,
								})}
								href={policy?.wordingPDF ?? ''}
								download="Group Possessions Policy Wording.pdf"
								target="_blank"
								variant="contained"
								color="secondary"
							>
								View my Cover
							</Button>
							<a
								href="https://enol.davies-group.com/assessment/afa0a9ed-1a89-4ec9-a240-af8dc48ab38d"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Button variant="outlined" color="secondary">
									Make a Claim
								</Button>
							</a>
						</Stack>

						{!!policy?.logo && (
							<Box
								sx={(theme) => ({
									display: { xs: 'none', lg: 'flex' },
									px: { xs: 3, sm: 6, md: 8, lg: 10 },
									py: { xs: 8, sm: 10, md: 12, lg: 14 },
									position: 'absolute',
									right: 0,
									bottom: 0,
									color: theme.palette.secondary.main,
									img: {
										bgcolor: theme.palette.grey[400],
										p: 1,
										borderRadius: 1,
										height: 'auto',
										maxHeight: 150,
										width: { xs: 150, sm: 200, md: 250, lg: 300 },
									},
								})}
							>
								<img src={policy?.logo} width={300} />
							</Box>
						)}
					</Box>

					{/* -----------YOUR COVER AT A GLANCE SECTION----------- */}

					<Box
						sx={(theme) => ({
							boxShadow: '15px 15px 42.2px 11px #00263E4F',
							background: theme.palette.common.white,
							borderColor: theme.palette.divider,
							borderWidth: 5,
							borderRadius: 2,
							borderStyle: 'solid',
							p: 2,
							py: { xs: 4, sm: 6, md: 8, lg: 10 },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						})}
					>
						<Typography
							component="h2"
							variant="h2"
							sx={(theme) => ({
								color: theme.palette.primary.dark,
							})}
						>
							Your Cover at a Glance
						</Typography>
						<Stack
							sx={{
								mt: { xs: 3, sm: 6 },
								maxWidth: '100%',
								width: '100%',
								px: {
									xs: 0,
									sm: 4,
									md: 8,
									lg: 12,
								},
							}}
							alignItems="center"
							justifyContent="center"
							spacing={{
								xs: 2,
								sm: 4,
								md: 6,
							}}
							className="info-content-cover-info"
						>
							<CoverSummary summary={policy?.coverSummaryInfo} />

							{/* -----------WHATS NOT COVERED SECTION----------- */}
							<WhatsNotCovered />

							<Typography
								sx={{
									px: { xs: 0, sm: 2, md: 4 },
									py: 1,
									textAlign: 'center',
									fontWeight: 700,

									textDecoration: 'underline',
									':hover': {
										textDecoration: 'none !important',
									},
								}}
								variant="caption"
								component="a"
								href={policy?.wordingPDF ?? ''}
								target="_blank"
								rel="noopener noreferrer"
							>
								Read full cover summary
							</Typography>

							{/* -----------WAIT YOU MIGHT NEED THIS SECTION----------- */}

							<Box>
								<Stack
									direction={{ xs: 'column', lg: 'row' }}
									spacing={{ xs: 3, md: 6 }}
									width="100%"
									sx={{
										mt: {
											xs: 0,
											sm: 2,
											md: 4,
											lg: 6,
											alignItems: 'center',
										},
									}}
								>
									<Box sx={{ flex: 1 }}>
										<Typography
											variant="h2"
											component="h2"
											sx={(theme) => ({
												color: theme.palette.primary.dark,
											})}
										>
											Wait! You might need this...
										</Typography>

										<Typography
											variant="body1"
											sx={(theme) => ({
												mt: 2,
												color: theme.palette.primary.dark,
												fontWeight: 500,

												maxWidth: '55ch',
											})}
										>
											It's great to have cover inside your accommodation,{' '}
											<Box component="span" sx={{ fontWeight: 800 }}>
												but make your cover follow you everywhere!
											</Box>
										</Typography>
									</Box>

									<Stack
										direction="column"
										spacing={4}
										sx={(theme) => ({
											p: { xs: 2, sm: 4, md: 6 },
											color: `${theme.palette.common.white} !important`,
											flex: 1,
											bgcolor: theme.palette.secondary.light,
											borderRadius: 2,
										})}
									>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-white.svg"
												width={50}
												height={50}
											/>

											<Typography
												variant="body2"
												sx={{
													color: 'white !important',
												}}
											>
												Extend your cover outside, on the go and abroad
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-white.svg"
												width={50}
												height={50}
											/>

											<Typography
												variant="body2"
												sx={{
													color: 'white !important',
												}}
											>
												Cover your items for Accidental Damage, Breakdown
												and Theft
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-white.svg"
												width={50}
												height={50}
											/>

											<Typography
												variant="body2"
												sx={{
													color: 'white !important',
												}}
											>
												Item cover up to £15,000
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-white.svg"
												width={50}
												height={50}
											/>

											<Typography
												variant="body2"
												sx={{
													color: 'white !important',
												}}
											>
												Cover your Phone and Laptop for £14.99 a month
											</Typography>
										</Stack>
										<a href="#upgrade">
											<Button
												sx={(theme) => ({
													bgcolor: 'white !important',
													color: `${theme.palette.secondary.light} !important`,
												})}
												color="tertiary"
											>
												Upgrade your Cover
											</Button>
										</a>
									</Stack>
								</Stack>
							</Box>
						</Stack>
					</Box>

					{/* -----------GET TO KNOW YOUR COVER----------- */}
					{isLoading ? null : policy?.disableFree ? null : (
						<Box
							id="video"
							sx={(theme) => ({
								boxShadow: '15px 15px 42.2px 11px #00263E4F',
								background:
									'radial-gradient(28.28% 66.84% at 2.85% 3.2%, rgba(0, 117, 188, 0.5) 0%, rgba(0, 117, 188, 0.3) 34%, rgba(255, 255, 255, 0) 100%), radial-gradient(31.97% 75.5% at 97.89% 90.33%, rgba(0, 117, 188, 0.5) 0%, rgba(0, 117, 188, 0.3) 34%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #00263E, #00263E)',
								borderColor: theme.palette.primary.dark,
								borderWidth: 5,
								borderRadius: 2,
								borderStyle: 'solid',
								p: 2,
								py: { xs: 8, sm: 10, md: 12, lg: 14 },
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							})}
						>
							<Box
								sx={{
									px: {
										xs: 0,
										sm: 4,
										md: 8,
										lg: 12,
									},
								}}
							>
								<Grid spacing={2} container>
									<Grid
										item
										xs={12}
										md={7}
										sx={{
											margin: { xs: 0, md: 'auto' },
											order: { xs: 2, md: 1 },
											minHeight: { xs: '46vw', md: 0 },
											mt: { xs: 2, md: 0 },
										}}
									>
										<Stack
											justifyContent="center"
											alignItems="center"
											sx={{ aspectRatio: '16/9' }}
										>
											<iframe
												className="info-content-cover-video"
												src="https://player.vimeo.com/video/853545987?h=064cd07e31"
											></iframe>
										</Stack>
									</Grid>

									<Grid
										item
										xs={12}
										md={5}
										sx={{
											order: { xs: 1, md: 2 },
											margin: { xs: 'auto', md: '0' },
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Typography
											component="h2"
											variant="h2"
											sx={(theme) => ({
												color: theme.palette.common.white,
												textAlign: { xs: 'center', md: 'left' },
											})}
										>
											Get to know Your Cover
										</Typography>

										<Typography
											variant="caption"
											sx={(theme) => ({
												mt: 2,
												color: theme.palette.common.white,
												fontWeight: 500,
												textAlign: { xs: 'center', md: 'left' },

												maxWidth: '55ch',
											})}
										>
											We’ve put together a short video to help answer any
											questions you may have about your policy.{' '}
											<Box component="span" sx={{ fontWeight: 800 }}>
												If you still need help head to our FAQs or speak to
												our chatbot.
											</Box>
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Box>
					)}

					{/* -----------UPGRADE YOUR COVER WITH----------- */}

					<Box
						id="upgrade"
						sx={(theme) => ({
							boxShadow: '15px 15px 42.2px 11px #00263E4F',
							background: theme.palette.common.white,
							borderColor: theme.palette.divider,
							borderWidth: 5,
							borderRadius: 2,
							borderStyle: 'solid',
							p: 2,
							py: { xs: 6, sm: 8, md: 10 },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						})}
					>
						<Stack
							justifyContent="center"
							alignItems="center"
							sx={{
								px: {
									xs: 0,
									sm: 4,
									md: 8,
									lg: 12,
								},
							}}
						>
							<Typography
								variant="subtitle1"
								sx={(theme) => ({ color: theme.palette.primary.dark })}
							>
								Upgrade Your Cover With
							</Typography>

							<Box mt={{ xs: 1, sm: 1, md: 2 }} sx={{ img: { maxWidth: '100%' } }}>
								<img
									src="/assets/main-logo-white.svg"
									alt="Arma Karma"
									width={547}
									height={56}
								/>
							</Box>

							<Typography
								mt={{ xs: 2, sm: 3, md: 4 }}
								variant="body1"
								textAlign="center"
							>
								Award-winning insurance subscription Arma Karma is here to ensure
								you have the cover you need for your stuff. What's even better, as a
								certified B-Corp (think fairtrade for business),{' '}
								<Box component="span" fontWeight={800}>
									25% of what they make goes to a charity of your choice.
								</Box>
							</Typography>

							<Stack
								width="100%"
								direction={{ xs: 'column', md: 'row' }}
								spacing={4}
								sx={{ mt: { xs: 3, sm: 4, md: 6 } }}
							>
								<Box
									width="100%"
									sx={(theme) => ({
										flex: 1,
										px: { xs: 2, md: 4, lg: 6 },
										py: { xs: 3, md: 6, lg: 8 },
										border: `5px solid ${theme.palette.primary.dark}`,
										boxShadow: '6px 4px 0px 0px #00263E',
										borderRadius: 2,
										background:
											'linear-gradient(150.58deg, #FFE29A 2.82%, #FFF9EA 50.19%, #FFE29A 95.15%)',
									})}
								>
									<Typography variant="h3">Exclusive Package</Typography>
									<Typography variant="subtitle1" fontWeight={700}>
										£14.99 a month
									</Typography>
									<Typography variant="caption" fontWeight={600}>
										To protect your phone and Laptop
									</Typography>

									<Stack width="100%" direction="column" gap={4} mt={2}>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												Cover up to £2,500 in value
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												Protection against Damage, Breakdown and Theft
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												Protection against Screen Damage and water damage
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												Unlimited Worldwide cover
											</Typography>
										</Stack>

										<Button
											sx={(theme) => ({
												width: 'fit-content',
												color: 'white !important',
												backgroundColor: `${theme.palette.secondary.light} !important`,
											})}
											onClick={() => {
												setRedirectUrl(
													`https://armakarma.insure/phone-and-laptop-insurance-bundle?utm_source=gallagher+assist&utm_medium=referral&utm_campaign=${policy?.policyNumber}`
												);
												setOpen(true);
											}}
											color="tertiary"
										>
											Get Deal
										</Button>
									</Stack>
								</Box>
								<Box
									sx={(theme) => ({
										flex: 1,
										px: { xs: 2, md: 4, lg: 6 },
										py: { xs: 3, md: 6, lg: 8 },
										border: `5px solid ${theme.palette.primary.dark}`,
										boxShadow: '6px 4px 0px 0px #00263E',
										borderRadius: 2,
									})}
								>
									<Typography variant="h3">Make Your Own</Typography>
									<Typography variant="subtitle1" fontWeight={700}>
										From £3.99 a month
									</Typography>
									<Typography variant="caption" fontWeight={600}>
										Choose up to 15-item-categories
									</Typography>

									<Stack direction="column" gap={4} mt={2}>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												Cover up to £3000 per item
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												From Instruments & Gadgets, to eyewear & jewellery
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												Protection against Damage, Breakdown and Theft
											</Typography>
										</Stack>
										<Stack
											direction="row"
											gap={2}
											alignItems="center"
											sx={{
												img: {
													xs: { width: 30, height: 30 },
													md: { width: 50, height: 50 },
												},
											}}
										>
											<img
												src="/assets/tick-green.svg"
												width={50}
												height={50}
											/>

											<Typography variant="body2" fontWeight={800}>
												Unlimited worldwide cover
											</Typography>
										</Stack>

										<Button
											sx={(theme) => ({
												width: 'fit-content',
												color: 'white !important',
												backgroundColor: `${theme.palette.secondary.light} !important`,
											})}
											onClick={() => {
												setRedirectUrl(
													`https://get-started.armakarma.insure/item-selection?utm_source=gallagher+assist&utm_medium=referral&utm_campaign=${policy?.policyNumber}`
												);
												setOpen(true);
											}}
											color="tertiary"
										>
											Get Started
										</Button>
									</Stack>
								</Box>
							</Stack>

							<Box>
								<Grid
									spacing={{ xs: 2, sm: 4, md: 6, lg: 10 }}
									container
									sx={{ mt: { xs: 8, sm: 10, md: 16 } }}
									alignItems="center"
								>
									<Grid
										display="flex"
										flexDirection="column"
										item
										xs={12}
										md={6}
										gap={{ xs: 2, md: 4 }}
									>
										<Typography variant="h2">
											Arma Karma's Monthly Giveaway! 🎁
										</Typography>
										<Typography variant="body2" fontWeight={500}>
											You have the chance to win one of our amazing prizes
											every month! By signing up to our monthly prize draw &
											mailing list, you could be one of the lucky winners!
										</Typography>
										<Typography variant="body2" fontWeight={500}>
											How do I enter? It’s simple and hassle-free. When you’re
											entering your email to get your insurance, just select
											the box that says you want to be added to Arma Karma’s
											monthly prize draw & mailing list. That’s it! Now you’re
											eligible to win exciting prizes!
										</Typography>
										<Typography
											variant="caption"
											fontWeight={500}
											sx={(theme) => ({
												a: {
													color: theme.palette.primary.dark,
													textDecoration: 'underline',
													':hover': {
														textDecoration: 'none',
													},
												},
											})}
										>
											Please note, over 250 entrants are needed for the
											monthly prize draw to commence. For more information,
											head to our {' '}
											<a
												href="https://armakarma.insure/terms-and-conditions"
												target="_blank"
											>
												Terms & Conditions.
											</a>
										</Typography>
									</Grid>

									<Grid item xs={12} md={6} gap={{ xs: 2, md: 4 }} flex={1}>
										<Box
											sx={(theme) => ({
												width: '100%',
												padding: { xs: 4, sm: 4, md: 6, lg: 8 },
												borderRadius: 2,
												border: `5px solid ${theme.palette.secondary.light}`,
												boxShadow: '6px 4px 0px 0px #4AA57F',
											})}
										>
											<Typography variant="h3">Giveaway Entry</Typography>

											<FormControl sx={{ flex: 1, width: '100%', mt: 1 }}>
												<Input
													error={
														!email.match(
															/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
														)
													}
													sx={(theme) => ({
														width: '100%',
														background: theme.palette.common.white,
														textAlign: 'start',
														borderRadius: theme.spacing(2),
														borderWidth: 2,
														borderColor: '#9A9A9A',
														borderStyle: 'solid',
														color: theme.palette.primary.dark,

														fontWeight: 700,
														fontFamily: 'Rethink Sans',
													})}
													placeholder="Enter your email..."
													className="email-form-input"
													disableUnderline
													inputProps={{
														sx: {
															padding: 2,
															px: { xs: 2, md: 4 },

															textAlign: 'start',
														},
													}}
													value={email}
													onChange={(e) => {
														setEmail(e.target.value);
													}}
												/>
												{/* <FormHelperText error={!!emailError}>{emailError}</FormHelperText> */}
											</FormControl>

											<Typography
												sx={(theme) => ({
													mt: 1,
													fontSize: '15px !important',
													color: '#898D8D',
													lineHeight: '20px !important',
												})}
												variant="body1"
											>
												By submitting your email, you are agreeing to be
												added to Arma Karma's mailing list.
											</Typography>

											{isSuccess && (
												<Typography
													variant="caption"
													sx={(theme) => ({
														color: theme.palette.secondary.light,
													})}
												>
													Giveaway successfully entered!
												</Typography>
											)}

											<Box mt={2}>
												<Button
													sx={(theme) => ({
														color: 'white !important',
														backgroundColor: `${theme.palette.secondary.light} !important`,
													})}
													onClick={async () => {
														const isValidEmail = email.match(
															/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
														);
														if (!isValidEmail) return;
														await updateCookieTrack({
															type: 'giveaway',
															notes: `Email "${email}" entered into giveaway`,
														});

														setEmail('');
													}}
													variant="contained"
													color="tertiary"
												>
													Submit
													{isEnteringGiveaway ? (
														<CircularProgress
															sx={{ color: 'white', ml: 1 }}
															size={22}
														/>
													) : null}
												</Button>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Stack>
					</Box>

					{/* --------DOWNLOAD YOUR COVER SECTION------------ */}

					<Box
						id="claim"
						sx={(theme) => ({
							boxShadow: '15px 15px 42.2px 11px #00263E4F',
							background:
								'radial-gradient(28.28% 66.84% at 2.85% 3.2%, rgba(0, 117, 188, 0.5) 0%, rgba(0, 117, 188, 0.3) 34%, rgba(255, 255, 255, 0) 100%), radial-gradient(31.97% 75.5% at 97.89% 90.33%, rgba(0, 117, 188, 0.5) 0%, rgba(0, 117, 188, 0.3) 34%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #00263E, #00263E)',
							borderColor: theme.palette.primary.dark,
							borderWidth: 5,
							borderRadius: 2,
							borderStyle: 'solid',
							p: 2,
							py: { xs: 8, sm: 10, md: 12, lg: 18 },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						})}
					>
						<Box
							id="documents"
							sx={{
								px: {
									xs: 0,
									sm: 4,
									md: 8,
									lg: 12,
								},
							}}
						>
							<Grid id="wallet" spacing={{ xs: 0, lg: 10 }} container>
								<Grid
									item
									xs={12}
									lg={7}
									sx={{
										order: { xs: 2, lg: 1 },
										margin: { xs: 0, md: 'auto' },
										mt: { xs: 2, md: 0 },
									}}
								>
									{policy && <WalletButtons policy={policy} />}
								</Grid>

								<Grid
									item
									xs={12}
									lg={5}
									sx={{
										order: { xs: 1, lg: 2 },
										margin: { xs: 'auto', md: '0' },
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: { xs: 'center', lg: 'start' },
										textAlign: { xs: 'center', lg: 'left' },
									}}
								>
									<Box>
										<Typography
											component="h2"
											variant="h2"
											sx={(theme) => ({
												color: theme.palette.common.white,
												textAlign: { xs: 'center', lg: 'left' },
											})}
										>
											Download Your Cover
										</Typography>

										<Typography
											variant="caption"
											sx={(theme) => ({
												mt: 2,
												color: theme.palette.common.white,
												fontWeight: 500,
												textAlign: { xs: 'center', lg: 'left' },
												maxWidth: '55ch',
											})}
										>
											Have your peace of mind by downloading your Cover or
											adding to your digital wallet.
										</Typography>
									</Box>
									<Box mt={2}>
										<Stack
											alignSelf={{ xs: 'center', lg: 'flex-start' }}
											justifyContent={{ xs: 'center', lg: 'flex-start' }}
											textAlign={{ xs: 'center', lg: 'left' }}
											direction="row"
											gap={2}
											flexWrap="wrap"
										>
											<Button
												component="a"
												href={policy?.wordingPDF ?? ''}
												download="Group Possessions Policy Wording.pdf"
												target="_blank"
												sx={(theme) => ({
													whiteSpace: 'nowrap',
													bgcolor: `${theme.palette.primary.main} !important`,
													color: 'white !important',
												})}
											>
												Download Policy
											</Button>
											<a
												href="https://enol.davies-group.com/assessment/afa0a9ed-1a89-4ec9-a240-af8dc48ab38d"
												target="_blank"
												rel="noopener noreferrer"
											>
												<Button
													sx={(theme) => ({
														whiteSpace: 'nowrap',
														bgcolor: `${theme.palette.primary.main} !important`,
														color: 'white !important',
													})}
												>
													Make a Claim
												</Button>
											</a>
										</Stack>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Box>

					{/* -----------RELATED BLOGS SECTION----------- */}

					{/* BLOGS SHOULD PROBABLY MAP OVER AN ARRAY BUT I CANT BE BOTHERED */}

					<Box
						sx={(theme) => ({
							boxShadow: '15px 15px 42.2px 11px #00263E4F',
							background: theme.palette.common.white,
							borderColor: theme.palette.divider,
							borderWidth: 5,
							borderRadius: 2,
							borderStyle: 'solid',
							p: 2,
							py: { xs: 8, sm: 10, md: 12, lg: 14 },

							display: 'flex',
							flexDirection: 'column',
						})}
					>
						<Box
							sx={{
								px: { xs: 0, sm: 6, md: 8, lg: 10 },
								position: 'relative',
							}}
						>
							<Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
								<Typography
									flex={1}
									component="h2"
									variant="h2"
									sx={(theme) => ({
										color: theme.palette.primary.dark,
										whiteSpace: 'nowrap',
									})}
								>
									Related Blogs
								</Typography>

								<Button
									onClick={() => {
										setRedirectUrl(
											`https://armakarma.insure/blog?utm_source=gallagher+assist&utm_medium=referral&utm_campaign=${policy?.policyNumber}`
										);
										setOpen(true);
									}}
									variant="outlined"
									sx={{
										py: { xs: '8px', md: '24px !important' },
										lineHeight: 1,
										height: 60,
										px: { xs: 0.5, md: 3 },
										border: { xs: 'none', md: '2px solid' },
										':hover': {
											border: { xs: 'none', md: '2px solid' },
										},
									}}
								>
									Read more{' '}
									<FontAwesomeIcon
										style={{ marginLeft: '8px' }}
										icon={faArrowRight}
									/>
								</Button>
							</Stack>

							<Stack
								onClick={() => {
									setRedirectUrl(
										`https://armakarma.insure/blog/help-for-renters-7-tips-for-first-time-renters?utm_source=gallagher+assist&utm_medium=referral&utm_campaign=${policy?.policyNumber}`
									);
									setOpen(true);
								}}
								sx={{
									borderRadius: 3,
									border: '4px solid #898D8D',
									transition: 'transform 0.2s',
									cursor: 'pointer',
									':hover': {
										transform: 'scale(1.01)',
									},
								}}
								direction="column"
								gap={4}
								mt={{ xs: 2, md: 10 }}
							>
								<Grid container>
									<Grid
										item
										xs={12}
										md={4}
										sx={{
											img: {
												objectFit: 'cover',
												height: '100%',
												borderTopLeftRadius: 8,
												borderBottomLeftRadius: { xs: 0, md: 8 },
												borderTopRightRadius: { xs: 8, md: 0 },
											},
										}}
									>
										<img src="/assets/blog-1.png" width="100%" />
									</Grid>
									<Grid item xs={12} md={8}>
										<Stack
											sx={(theme) => ({
												p: { xs: 2, sm: 4, md: 6 },
											})}
											direction="column"
											gap={2}
										>
											<Typography variant="body2" fontWeight={800}>
												Help for renters: 7 tips for first time renters
											</Typography>
											<Typography variant="caption">
												It's an exciting time moving out of your parents
												house in to your own place. It’s a big step into
												adulthood. However, there are lots of things to
												consider you may not have initially thought about.
											</Typography>
											<Typography variant="caption">
												We're here to share our top tips from the Arma Karma
												team for first time renters.
											</Typography>

											<Typography
												sx={{
													marginLeft: 'auto',
													display: { xs: 'block', md: 'none' },
												}}
												fontWeight={700}
											>
												Read more{' '}
												<FontAwesomeIcon
													style={{ marginLeft: '8px' }}
													icon={faArrowRight}
												/>
											</Typography>
										</Stack>
									</Grid>
								</Grid>
							</Stack>

							<Stack
								onClick={() => {
									setRedirectUrl(
										`https://armakarma.insure/blog/how-to-make-living-with-housemates-easier?utm_source=gallagher+assist&utm_medium=referral&utm_campaign=${policy?.policyNumber}`
									);
									setOpen(true);
								}}
								sx={{
									borderRadius: 3,
									border: '4px solid #898D8D',
									transition: 'transform 0.2s',
									cursor: 'pointer',
									':hover': {
										transform: 'scale(1.01)',
									},
								}}
								direction="column"
								gap={4}
								mt={{ xs: 4, md: 6 }}
							>
								<Grid container>
									<Grid
										item
										xs={12}
										md={4}
										sx={{
											img: {
												objectFit: 'cover',
												height: '100%',
												borderTopLeftRadius: 8,
												borderBottomLeftRadius: { xs: 0, md: 8 },
												borderTopRightRadius: { xs: 8, md: 0 },
											},
										}}
									>
										<img src="/assets/blog-2.png" width="100%" />
									</Grid>
									<Grid item xs={12} md={8}>
										<Stack
											sx={(theme) => ({
												p: { xs: 2, sm: 4, md: 6 },
											})}
											direction="column"
											gap={2}
										>
											<Typography variant="body2" fontWeight={800}>
												How to make living with housemates easier
											</Typography>
											<Typography variant="caption">
												Living with people can be trying at times, there’s
												no two ways about it. From different opinions on
												washing up to different party vibes, it can be
												difficult adjusting to another person’s routine. So
												we’ve compiled a list of the five best apps or
												subscriptions you can use to make your house share
												run a little bit smoother, and minimise housemate
												beef...
											</Typography>

											<Typography
												sx={{
													marginLeft: 'auto',
													display: { xs: 'block', md: 'none' },
												}}
												fontWeight={700}
											>
												Read more{' '}
												<FontAwesomeIcon
													style={{ marginLeft: '8px' }}
													icon={faArrowRight}
												/>
											</Typography>
										</Stack>
									</Grid>
								</Grid>
							</Stack>

							<Stack
								onClick={() => {
									setRedirectUrl(
										`https://armakarma.insure/blog/10-things-to-consider-when-choosing-a-roommate?utm_source=gallagher+assist&utm_medium=referral&utm_campaign=${policy?.policyNumber}`
									);
									setOpen(true);
								}}
								sx={{
									borderRadius: 3,
									border: '4px solid #898D8D',
									transition: 'transform 0.2s',
									cursor: 'pointer',
									':hover': {
										transform: 'scale(1.01)',
									},
								}}
								direction="column"
								gap={4}
								mt={{ xs: 4, md: 6 }}
							>
								<Grid container>
									<Grid
										item
										xs={12}
										md={4}
										sx={{
											img: {
												objectFit: 'cover',
												height: '100%',
												borderTopLeftRadius: 8,
												borderBottomLeftRadius: { xs: 0, md: 8 },
												borderTopRightRadius: { xs: 8, md: 0 },
											},
										}}
									>
										<img src="/assets/blog-3.png" width="100%" />
									</Grid>
									<Grid item xs={12} md={8}>
										<Stack
											sx={(theme) => ({
												p: { xs: 2, sm: 4, md: 6 },
											})}
											direction="column"
											gap={2}
										>
											<Typography variant="body2" fontWeight={800}>
												10 things to consider when choosing a roommate
											</Typography>
											<Typography variant="caption">
												Picking the right roommate is a big decision as
												you'll be living together 24/7 for months, maybe
												years! That's why there's a lot to consider for what
												you're looking for in a roommate and how to pick
												one. We've listed our top 10 points to help you
												navigate the right decision...
											</Typography>

											<Typography
												sx={{
													marginLeft: 'auto',
													display: { xs: 'block', md: 'none' },
												}}
												fontWeight={700}
											>
												Read more{' '}
												<FontAwesomeIcon
													style={{ marginLeft: '8px' }}
													icon={faArrowRight}
												/>
											</Typography>
										</Stack>
									</Grid>
								</Grid>
							</Stack>
						</Box>
					</Box>

					{/* -----------FAQS---------- */}
					<Box
						id="faqs"
						sx={(theme) => ({
							boxShadow: '15px 15px 42.2px 11px #00263E4F',
							background: theme.palette.common.white,
							borderColor: theme.palette.divider,
							borderWidth: 5,
							borderRadius: 2,
							borderStyle: 'solid',
							px: { xs: 3, sm: 6, md: 8, lg: 10 },
							pt: 3,
							pb: 3,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						})}
					>
						<Faqs faqData={faqs as any} disableContainer />
					</Box>
				</Container>
			</Box>

			<Footer />
		</Styles>
	);
};

const Styles = styled.div`
	.mx-auto {
		${({ theme }) => theme.breakpoints.down('sm')} {
			margin: 0 auto;
		}
	}
	.download-bar {
		display: flex;
		background: ${({ theme }) => theme.palette.secondary.main};
		color: ${({ theme }) => theme.palette.common.white};
		text-align: center;
		padding: ${({ theme }) => theme.spacing(2, 0)};
		justify-content: center;
		position: relative;
		.download-bar-scale-text {
			${({ theme }) => theme.breakpoints.down('md')} {
				font-size: ${() => pxToRem(36)};
			}
			${({ theme }) => theme.breakpoints.down('sm')} {
				font-size: ${() => pxToRem(28)};
			}
			@media (max-width: 600px) {
				font-size: ${() => pxToRem(22)};
			}
		}
	}
	.header {
		position: relative;
		width: 100%;
		background: linear-gradient(to right, white 0%, white 43%, transparent 100%);
		${({ theme }) => theme.breakpoints.down('sm')} {
			background: linear-gradient(
				to top,
				white 0%,
				rgba(255, 255, 255, 0.5) 80%,
				transparent 100%
			);
		}
		overflow: hidden;
		max-height: max-content;
		height: 100%;
		padding: ${({ theme }) => theme.spacing(18, 2)};
		&-hero {
			${({ theme }) => theme.breakpoints.down('sm')} {
				text-align: center;
				align-items: center;
				display: flex;
				flex-direction: column;
			}
		}
		&-image {
			z-index: -1;
			margin-left: auto;
			position: absolute;
			width: 100%;
			margin-bottom: auto;
			top: 0;
			& > img {
				${({ theme }) => theme.breakpoints.up('md')} {
					width: 65%;
				}
				${({ theme }) => theme.breakpoints.down('sm')} {
					width: auto;
					height: auto;
				}
				object-fit: contain;
				height: auto;
				min-height: 100%;
				height: auto;
				object-position: 100% 100%;
				margin-left: auto;
				right: 0;
				position: absolute;
				top: 0;
			}
		}
	}

	.hero-text-slanted {
		transform: rotate(0deg);
		position: relative;
		color: ${({ theme }) => theme.palette.common.white};
		padding: ${({ theme }) => theme.spacing(0, 2)};
		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			background: #fff;
			top: 0;
			left: 0;
			z-index: -1;
			transform: rotate(-1deg);
			background: ${({ theme }) => theme.palette.secondary.main};
		}
	}

	.info-content {
		color: #002a44;
		height: 100%;
		background: rgb(255, 255, 255);

		&-cover-info {
			* {
				color: ${({ theme }) => theme.palette.secondary.darker};
			}
		}

		&-title {
			&-text {
				${({ theme }) => theme.breakpoints.down('md')} {
					font-size: 1.7rem;
				}
			}
			align-items: center;
			${({ theme }) => theme.breakpoints.up('md')} {
				margin-left: -60px;
			}
			&-number {
				width: 60px;
				font-size: ${pxToRem(80)};
				color: #0075bc;
			}
		}

		&-grid {
			padding-top: ${({ theme }) => theme.spacing(4)};
			min-height: 0;
			flex-shrink: initial;
			width: 100%;
			&-stack {
				min-height: 0;
				flex: 1;
				padding-bottom: ${({ theme }) => theme.spacing(8)};

				&-download {
					flex: 1;
					max-width: 200px;
					padding: ${({ theme }) => theme.spacing(1, 0)};

					&-arrow {
						margin: auto;
						font-size: ${({ theme }) => theme.spacing(6)};
					}

					&.make-claim {
						${({ theme }) => theme.breakpoints.only('xs')} {
							font-size: 12px;
						}
					}

					&.email,
					&.download {
						svg {
							width: 52px;
							height: 52px;
						}
					}
				}
			}
			margin-top: ${({ theme }) => theme.spacing(3)};
		}

		&-cover {
			&-video {
				background-color: ${({ theme }) => theme.palette.secondary.darker};
				border: 5px solid ${({ theme }) => theme.palette.common.white};
				border-radius: 10px;
				bkit-box-shadow: 4px 4px 0px 1px ${({ theme }) => theme.palette.common.white};
				-moz-box-shadow: 4px 4px 0px 1px ${({ theme }) => theme.palette.common.white};
				box-shadow: 4px 4px 0px 1px ${({ theme }) => theme.palette.common.white};
				aspect-ratio: 16/9.1111111111;
				width: 100%;
				${({ theme }) => theme.breakpoints.up('md')} {
					width: 90%;
				}
			}
		}
	}
`;

export default Info;
