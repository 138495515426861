import { Theme } from '@mui/material/styles';
import { css } from '@emotion/react';
import { FONT_SECONDARY } from '~/theme/typography';
import { IconButtonTransparent } from '~/theme/components/IconButton';

const Card = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiCard: {
			styleOverrides: {
				root: css`
					${IconButtonTransparent(theme)};
				`,
			},
		},
	};
};

export default Card;
