import React from 'react';
import styled from '@emotion/styled';
import { CircularProgress, Stack } from '@mui/material';

const FallbackCircle: React.FC<{}> = () => {
	return (
		<Styles>
			<Stack justifyContent="center" alignItems="center" height="100vh" width="100%">
				<CircularProgress className="fallback-icon" />
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.fallback {
		&-icon {
			color: #6fa0d6;
		}
	}
`;

export default FallbackCircle;
