import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Stack, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RedirectDialog from '~/components/RedirectDialog';
import { FONT_DAZZLE } from '~/theme/typography';

const ArmaHeader: React.FC<{}> = () => {
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Styles>
			<RedirectDialog
				link={'https://www.armakarma.insure'}
				open={open}
				handleClose={handleClose}
			/>
			<Stack mt={8}>
				<Stack gap={3} className="arma-header">
					<img
						src="https://arma-karma.s3.eu-west-2.amazonaws.com/image-20230810-113802.png "
						className="arma-header-logo"
					/>

					<Button onClick={handleClick} className="arma-header-get-started">
						GET STARTED
					</Button>
				</Stack>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.dialog {
		padding: ${({ theme }) => theme.spacing(6)};

		&-logo {
			margin: ${({ theme }) => theme.spacing(2, 0)};
			height: 10px;
		}
		&-loader {
			width: 200px;
			color: #3e926d;
		}
	}

	.arma-header {
		&-cover {
			padding: ${({ theme }) => theme.spacing(2, 0)};

			${({ theme }) => theme.breakpoints.down('md')} {
				width: 100%;
			}
			${({ theme }) => theme.breakpoints.up('lg')} {
				width: 50%;
			}
		}
		&-logo {
			width: 50%;
			${({ theme }) => theme.breakpoints.down('md')} {
				width: 80%;
			}
			${({ theme }) => theme.breakpoints.up('lg')} {
				width: 35%;
			}
		}
		margin-bottom: ${({ theme }) => theme.spacing(4)};
		&-cover {
			font-family: 'Gilroy', Helvetica, sans-serif;
		}
		&-icon {
			color: white;
		}
		&-get-started {
			font-family: ${FONT_DAZZLE};
			border-radius: 50px;
			font-weight: 800;
			text-transform: uppercase;
			background-color: ${({ theme }) => theme.palette.primary.darker}!important;
			/* font-family: ${({ theme }) => theme.typography.body1.fontFamily}; */
			padding: ${({ theme }) => theme.spacing(1.5, 5.5)};
			box-shadow: none;
			&:hover,
			&:focus {
				/* background-color: opacity !important; */
				background-color: ${({ theme }) => theme.palette.primary.dark}!important;
				box-shadow: none;
			}
			margin-top: ${({ theme }) => theme.spacing(2)};
			font-size: ${({ theme }) => theme.spacing(3)};
			color: white;
			background-color: #224832;
		}
		&-what-else {
			margin-top: ${({ theme }) => theme.spacing(6)};
		}
		&-perlingo {
			font-family: 'Gilroy', Helvetica, sans-serif;
			margin-top: ${({ theme }) => theme.spacing(1)};
			text-align: center;
			font-weight: bold;
			&-marker {
				background: linear-gradient(
					/* the direction of the gradient: */ to bottom,
					/* the starting colour: */ transparent,
					/* the colour continues, until the colour stop
                           at 40%: */
						transparent 40%,
					/* the next colour, starting at the same point,
                           so the colour change is abrupt rather than a
                           smooth gradient: */
						#ff0 40%,
					/* the colour continues until the next colour-stop: */ #ff0 60%,
					/* at the next colour stop the colour changes again,
                           this time back to transparent: */
						transparent 60%,
					/* and remains transparent until the end of the linear-gradient: */ transparent
				);
				margin: 13px 0;
				padding: 0 0.5em;
				transform: translateX(-20px, -17px);
			}
		}
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: auto;
		margin-top: ${({ theme }) => theme.spacing(4)};
		width: 80%;
	}
`;

export default ArmaHeader;
