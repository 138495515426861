import { css } from '@emotion/react';
import { Theme } from '@mui/material/styles';

const Container = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiContainer: {
			styleOverrides: {
				root: css``,
			},
			defaultProps: {
				maxWidth: 'lg',
			},
		},
	};
};

export default Container;
