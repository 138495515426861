import React from 'react';
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FONT_PRIMARY } from '~/theme/typography';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { Policy } from '~/models/policy';

const CoverSummary: React.FC<{ summary?: Policy['coverSummaryInfo'] }> = (summary) => {
	return (
		<Styles>
			<TableContainer component={Paper}>
				<Table className="cover-summary-table" sx={{ background: grey['100'] }}>
					<TableHead>
						<TableCell colSpan={2}>Cover description</TableCell>
						<TableCell align="right">Sum insured</TableCell>
					</TableHead>
					{summary.summary?.map((section, index) => (
						<React.Fragment key={index}>
							<TableRow>
								<TableCell
									sx={{ whiteSpace: 'nowrap' }}
									rowSpan={section.items.length}
								>
									{section.title}
								</TableCell>
								<TableCell>{section.items[0].title}</TableCell>
								<TableCell align="right">
									{section?.items?.map((item, inx) => {
										return <>{section.items[0].sumInsured[inx]}</>;
									})}
								</TableCell>
							</TableRow>
							{section.items.slice(1).map((item, idx) => (
								<TableRow key={idx}>
									<TableCell>{item.title}</TableCell>
									<TableCell align="right">{item.sumInsured[0]}</TableCell>
								</TableRow>
							))}
						</React.Fragment>
					))}
				</Table>
			</TableContainer>
		</Styles>
	);
};

const Styles = styled.div`
	* {
		font-family: ${FONT_PRIMARY} !important;
	}

	.cover-summary-table {
		td,
		th {
			border-bottom: 1px solid ${grey['300']};

			&[rowspan] {
				border-right: 1px solid ${grey['300']};
			}
		}
	}
`;

export default CoverSummary;
