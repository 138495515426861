import { css } from '@emotion/react';
import {FONT_PRIMARY, FONT_SECONDARY} from '~/theme/typography';
import {darken, lighten, Theme} from '@mui/material';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';


const DatePicker = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiDatePicker: {
			styleOverrides: {
				root: css`
					background: red;
				`,
			},
			defaultProps: {

			},
		},
	};
};

export default DatePicker;
